import Home08 from "./Home08";
import OpenMicsPage from "./OpenMicsPage";
import Authors02 from "./Authors02";




const routes = [
  { path: '/', component: <Home08 />},
  { path: '/drops', component: <OpenMicsPage />},
  { path: '/more-info', component: <Authors02 />},
  /*
  { path: '/home-02', component: <Home02 />},
  { path: '/home-03', component: <Home03 />},
  { path: '/home-04', component: <Home04 />},
  { path: '/shows', component: <Shows />},
  { path: '/home-06', component: <Home06 />},
  { path: '/home-07', component: <Home07 />},
  { path: '/explore-01', component: <Explore01 />},
  { path: '/drops-calendar', component: <OpenMicsPage />},
  { path: '/explore-03', component: <Explore03 />},
  { path: '/explore-04', component: <Explore04 />},
  { path: '/live-auctions', component: <LiveAuctions />},
  { path: '/item-details-01', component: <ItemDetails01 />},
  { path: '/item-details-02', component: <ItemDetails02 />},
  { path: '/activity-01', component: <Activity01 />},
  { path: '/activity-02', component: <Activity02 />},
  { path: '/blog', component: <Blog />},
  { path: '/blog-details', component: <BlogDetails />},
  { path: '/help-center', component: <HelpCenter />},
  { path: '/authors-01', component: <Authors01 />},
  { path: '/wallet-connect', component: <WalletConnect />},
  { path: '/create-item', component: <CreateItem />},
  { path: '/edit-profile', component: <EditProfile />},
  { path: '/ranking', component: <Ranking />},
  { path: '/login', component: <Login />},
  { path: '/sign-up', component: <SignUp />},
  { path: '/no-result', component: <NoResult />},
  { path: '/faq', component: <FAQ />},
  { path: '/contact-01', component: <Contact01 />},
  { path: '/contact-02', component: <Contact02 />},*/
]

export default routes;
const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
    },
    /*{
        id: 2,
        name: 'NFT Drop Calendar',
        links: '/drops',
    } */
]

export default menus;
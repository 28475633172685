import React , { useState , Fragment, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import LoadingBar from 'react-top-loading-bar'
import 'react-tabs/style/react-tabs.css';
import CardModal from '../CardModal';
import '@neftyblocks/market';
 
const OpenMics = () => {
    const [dataTab] = useState(
        [
            {
                id: 0,
                title: "Sunday",
                shortened: "Sun."
            },
            {
                id: 1,
                title: "Monday",
                shortened: "Mon."
            },
            {
                id: 2,
                title: "Tuesday",
                shortened: "Tues."
            },
            {
                id: 3,
                title: "Wednesday",
                shortened: "Wed."
            },
            {
                id: 4,
                title: "Thursday",
                shortened: "Thurs."
            },
            {
                id: 5,
                title: "Friday",
                shortened: "Fri."
            },
            {
                id: 6,
                title: "Saturday",
                shortened: "Sat."
            }
        ]
    )
    const [progress,setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [dataPanel, setItems] = useState([
        {
            id: 0,
            idVal: "Sunday",
            dataContent: []
        },
        {
            id: 1,
            idVal: "Monday",
            dataContent: []
        },
        {
            id: 2,
            idVal: "Tuesday",
            dataContent: []
        },
        {
            id: 3,
            idVal: "Wednesday",
            dataContent: []
        },
        {
            id: 4,
            idVal: "Thursday",
            dataContent: []
        },
        {
            id: 5,
            idVal: "Friday",
            dataContent: []
        },
        {
            id: 6,
            idVal: "Saturday",
            dataContent: []
        }
                
    ]);
    var dayMap = 
    {
        "Sunday" : 0,
        "Monday" : 1,
        "Tuesday" : 2,
        "Wednesday" : 3,
        "Thursday" : 4,
        "Friday" : 5,
        "Saturday" : 6
    }

    useEffect(() => {
        setProgress(40)
        fetch('https://sheets.googleapis.com/v4/spreadsheets/1VT0608JIG2CN2FYGQuPhmYU-l44ZSE9J0Lf8HSysfe0/values/Sheet1?key=AIzaSyCcgBWeO-uFe_GzS5aM1LUu1Gb9vBvkLE8')
        .then(result=>result.json())
        .then((items)=>{
            setProgress(80)
            var keys = items.values[0];
            items.values.splice(0,1);
            var results = [];
            items.values.forEach((values, index) => {
                var newObj = {id: index};
                values.forEach((value, i) =>{
                    if(keys[i] === "dayName"){ 
                        newObj[keys[i]] = dayMap[value]
                    } else{
                         newObj[keys[i]] = value
                        }
                    }
                );
                results.push(newObj)
            }          
            )
            var weekDayArray = dataPanel;
            results.forEach((value,i) =>{
                weekDayArray[value.dayName].dataContent.push(value)
            })
            console.log(results)
            console.log(weekDayArray)
            setProgress(100)
            setItems(weekDayArray);
            setIsLoaded(true);
        },
        (error) => {
            setProgress(100)
            setIsLoaded(true);
            setError(error);
          }
    )
    }, [])

    const d = new Date();
    var day = d.getDay();
    const [tabIndex, setTabIndex] = useState(day);

    const [modalShow, setModalShow] = useState(false);
    if(isLoaded){
        return (
            <Fragment>
                <div className="tf-section sc-explore-2 open-mics">
                    <div className="themesflat-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="flat-tabs explore-tab">
                                    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                                        <TabList>
                                            {
                                                dataTab.map(data=> (
                                                    <Tab key={data.id} >{data.title}</Tab>
                                                ))
                                            }
                                        </TabList>
                                        {
                                            dataPanel.map(data =>(
                                                <TabPanel key={data.id}>
                                                    {
                                                        <section className="tf-section tf-rank tf-mics">
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="table-ranking">
                                                                        <div className="flex th-title">
                                                                            <div className="column1">
                                                                                <h3>{dataTab[tabIndex].shortened} Drops</h3>
                                                                            </div>
                                                                            <div className="column">
                                                                                <h3>Date</h3>
                                                                            </div>
                                                                            <div className="column">
                                                                                <h3>Time</h3>
                                                                            </div>
                                                                            <div className="column">
                                                                                <h3>Website</h3>
                                                                            </div>
                                                                            <div className="column">
                                                                                <h3>Socials</h3>
                                                                            </div>
                                                                            <div className="column notes-title">
                                                                                <h3>Drop Details</h3>
                                                                            </div>
    
                                                                        </div>
                                                                        {
                                                                            data.dataContent.map((item,index) => (
                                                                                <div key={item.id +"#"+ index} className="fl-item2">
                                                                                    <div className="item flex">
                                                                                        <div className="infor-item flex column1">
                                                                                            <div className="media">
                                                                                                <img src={item.imageUrl} alt="Axies" />
                                                                                            </div>
                                                                                            <div className="content-collection pad-t-4">
                                                                                                <h5 className="title mt-29"><a href={item.atomicHubLink || "#"}>{item.collectionName}</a></h5>
                                                                                                <div className="author flex">
                                                                                                    <div className="content">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="column td1">
                                                                                            <span>{item.dropDate}</span>
                                                                                        </div>
                                                                                        <div className="column td2">
                                                                                            <span>{item.dropTime}</span>
                                                                                        </div>
                                                                                        <div className="column td3">
                                                                                            <span><a href={item.Website || "#"}>Visit Site</a></span>
                                                                                        </div>
                                                                                        <div className="column td4">
                                                                                            <span><a href={item.twitterLink || "#"}><i className="fab fa-twitter"></i></a></span>
                                                                                        </div>
                                                                                        <div className="column td5 notes">
                                                                                            <span>{item.dropDescription}</span>
                                                                                        </div>
                        
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                        {
                                                                            data.dataContent.length === 0 &&
                                                                                <h5>Nothing this day</h5>
                                                                            
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    //     data.dataContent.slice(0,visible).map (item=>(
                                                    //     <div key={item.id} className={`sc-card-product explode style2 mg-bt ${item.feature ? 'comingsoon' : '' } `}>                               
                                                    //         <div className="card-media">
                                                    //             <Link to="/item-details-01"><img src={item.img} alt="Axies" /></Link>
                                                    //             <div className="button-place-bid">
                                                    //                 <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3"><span>Place Bid</span></button>
                                                    //             </div>
                                                    //             <Link to="/login" className="wishlist-button heart"><span className="number-like">{item.wishlist}</span></Link>
                                                    //             <div className="coming-soon">{item.feature}</div>
                                                    //         </div>
                                                    //         <div className="card-title">
                                                    //             <h5><Link to="/item-details-01">"{item.title}"</Link></h5>
                                                                
                                                    //         </div>
                                                    //         <div className="meta-info">
                                                    //             <div className="author">
                                                    //                 <div className="avatar">
                                                    //                     <img src={item.imgAuthor} alt="Axies" />
                                                    //                 </div>
                                                    //                 <div className="info">
                                                    //                     <span>Creator</span>
                                                    //                     <h6> <Link to="/authors-02">{item.nameAuthor}</Link> </h6>
                                                    //                 </div>
                                                    //             </div>
                                                    //             <div className="tags">{item.tags}</div>
                                                    //         </div>
                                                    //         <div className="card-bottom style-explode">
                                                    //             <div className="price">
                                                    //                 <span>Current Bid</span>
                                                    //                 <div className="price-details">
                                                    //                     <h5>{item.price}</h5>
                                                    //                     <span>= {item.priceChange}</span>
                                                    //                 </div>
                                                    //             </div>
                                                    //             <Link to="/activity-01" className="view-history reload">View History</Link>
                                                    //         </div>
                                                    //     </div>
                                                    //     ))
                                                    // }
                                                    // {
                                                    //     visible < data.dataContent.length && 
                                                    //     <div className="col-md-12 wrap-inner load-more text-center"> 
                                                    //         <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Load More</span></Link>
                                                    //     </div>
                                                    // }
                                                }
                                                </TabPanel>
                                            ))
                                        }
                                    </Tabs>
                                </div> 
                            </div>   
                        </div>
                    </div>
                </div>
                <CardModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </Fragment>
        );
    } else if(!isLoaded){
        return(
            <Fragment>
                <LoadingBar height={5} loaderSpeed={1000} color='#5142FC' progress={progress} onLoaderFinished={() => setProgress(0)} />
                <div className="tf-section sc-explore-2 open-mics">
                    <div className="themesflat-container">
                        <div className="row">
                            <div className="col-md-12">
                            <h2>Loading...</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
    
}

export default OpenMics;

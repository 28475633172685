import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer'
import ItemContent from '../components/layouts/home-8/ItemContent';


const Home08 = () => {
  return (
    <div className='home-8'>
        <Header />
        <section className="tf-item tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        
                        
                        <div className="col-box-83">
                            <ItemContent />
                        </div>
                    </div>
                </div>
            </section>
          <Footer />
    </div>
  );
}

export default Home08;
import React , { useState} from 'react';
import { useLocation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import HeaderStyle2 from '../components/header/HeaderStyle2'
import Footer from '../components/footer/Footer';
import CardModal from '../components/layouts/CardModal';




const Authors02 = () => {
    const location = useLocation()
    console.log(location.state)
    const {collectionTitle, collectionImg, collectionInfo, collectionWebsite, collectionTwitter, collection, collectionType} = location.state
    console.log(collectionTitle + collectionImg)
    let coll = Boolean
    let collTweet = Boolean
    function showHideCollection(){
    if(collection === "null"){
        coll = false
        console.log("Item has no collection")
    }else{
        coll = true
        console.log("Item Has collection")
    }
    }
    function showHideTwitter(){
        if(collectionTwitter === "null"){
           collTweet = false
           console.log("Item has no twitter")
        }else{
            collTweet = true
            console.log("Item Has Twitter")
        }
        }
    showHideCollection()
    showHideTwitter()
    const [menuTab] = useState(
        [
            {
                class: 'active',
                name: 'Market'
            },
            
        ]
    )
    //TODO: FIX LINES 49 - 54
    //const [collectionTitle, setCart] = useState(localStorage.getItem('cart'));

    //useEffect(()=>{
      //  localStorage.setItem('cart', cart)
    //},[cart]);
    // Example config with all options



    const [visible , setVisible] = useState(8);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 4);
    }

    const [modalShow, setModalShow] = useState(false);

    return (
        <div className='authors-2'>
            <HeaderStyle2 />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">{collectionTitle} <br /></h1>
                                <h3 className="text-center">{collectionType}</h3>
                            </div>
                           
                        </div>
                    </div>
                </div>                    
            </section>
            <section className="tf-section authors">
                <div className="themesflat-container">
                    <div className="flat-tabs tab-authors">
                        <div className="author-profile flex">
                            <div className="feature-profile">
                                <img src={collectionImg} alt="Axies" className="avatar" />
                            </div>
                            <div className="infor-profile">
                                <span>{collectionType}</span>
                                <h2 className="title">{collectionTitle}</h2>
                                <p className="content">{collectionInfo}</p>
                                
                            </div>
                            <div className="widget-social style-3">
                                <ul>
                                    <li style={{visibility: collTweet ? 'visible' : 'hidden' }}><a href={collectionTwitter} target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a></li>
                                    
                                    
                                </ul>
                                <div className="btn-profile">
                                    <a href={collectionWebsite} target="_blank" rel="noreferrer" className="sc-button style-1 follow">Website</a>
                                </div>
                            </div>
                        </div>
                        <div style={{display: coll ? 'visible' : 'none' }}>
                        <Tabs >
                            <TabList>
                                {
                                    menuTab.map((item,index) => (
                                        <Tab key={index}>{item.name}</Tab>
                                    ))
                                }
                            </TabList>

                            <div className="content-tab" id="collectionItems" style={{visibility: coll ? 'visible' : 'hidden' }}>
                                <div className="content-inner">
                                    <div className="row">
                                    {
                                        
                                            <TabPanel >
                                                
                                                   
                                                        <div className="col-lg col-md-6 col-12">
                                                            <neftyblocks-market collection={collection} limit="20" redirect="https://waxdapps.io" ></neftyblocks-market>
                                                        </div>
                                                    
                                                
                                                
                                            </TabPanel>
                                        
                                    }
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                        </div>
                    </div>
                </div>
            </section>
            <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <Footer />
        </div>
    );
}

export default Authors02;
